
import Cookies from "js-cookie";
window.Cookies = Cookies;


import { jsPDF } from "jspdf";
import 'jspdf-autotable';

export default {
    data() {
        return {


            dataPDF_servicios: null,
            dataPDF_terminos: null,

            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
        };
    },
    created() {
        

    },
    mounted() {
        this.loadDataInfo();
    },
    methods: {
        
        exportarPDF() {
            const doc = new jsPDF('landscape', 'mm', 'a4');



            

            //////////////////

            //let servicios = e.row.data.serviciosjson;//'[{"servicio":"Servicio1", "cantidad": 1, "subtotal":1.00, "iva":1.00, "total":1.00}]';
            //let terminos = e.row.data.terminosjson;//'[{"id": 1, "termino":"Termino 1"}, {"id": 2, "termino":"Termino 2"}]';



            //let serviciosJSON = JSON.parse(servicios);
            //let terminosJSON = JSON.parse(terminos);

            //Centrar el texto
            let url = 'https://www.lmeraki.com/';
            var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
            //var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            
            //doc.setFontSize(15);  
            //doc.text("TITULO 1", 130, 30, {align: "left"});

            var img = new Image();
            img.src = require("../assets/logo.png");
            doc.addImage(img, "PNG", 15, 3, 40, 20);

            doc.setFontSize(10);
            doc.setFont('helvetica','bold');
            doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
            doc.setFont('helvetica','normal');
            doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
            doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
            doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url});

            doc.setFontSize(8);
            doc.setFont('helvetica','bold');
            doc.text("NOMBRE DE LA EMPRESA", 20, 35, { align: "left" });
            doc.text("FECHA", 230, 35, { align: "left" });
            doc.text("DIRECCIÓN", 33, 40, { align: "left" });
            doc.text("RESPONSABLE DE VISITA", 20, 45, { align: "left" });
            doc.text("PUESTO", 200, 45, { align: "left" });
            doc.text("TELÉFONO", 33, 50, { align: "left" });
            doc.text("INICIO DE VISITA", 165, 50, { align: "left" });
            doc.text("FIN DE VISITA", 235, 50, { align: "left" });

            doc.rect(15,30,275,22);

            var columns_tabla = [
                { title: "", dataKey: "elemento" },
                { title: "", dataKey: "res" },
            ];

            var datos_motivo_visita = [
                { elemento: "VISITA INICIAL DE CAMPO", res: "OK" },
                { elemento: "EVALUACIÓN DE CUMPLIMIENTO AMBIENTAL (ECA)", res: "OK" },
                { elemento: "CONDICIONANTES DE RESOLUTIVOS", res: "OK" },
                { elemento: "MEDIDAS CORRECTIVAS DE ACTA DE INSPECCIÓN", res: "OK" },
                { elemento: "RECOPILACIÓN DE INFORMACIÓN PARA TRÁMITE/ESTUDIO", res: "OK" },
                { elemento: "ATENCIÓN DE VISITA DE AUTORIDAD", res: "OK" },
                { elemento: "ENTREGA DE DOCUMENTACIÓN DE TRÁMITES REALIZADOS", res: "OK" },
                { elemento: "CURSOS DE CAPACITACIÓN", res: "OK" },
                { elemento: "OTRO:", res: "OK" }
            ];

            var datos_emisiones_atmos = [
                { elemento: "BITÁCORAS DE MTTO. Y OPERACIÓN", res: "OK" },
                { elemento: "CÉDULA DE OPERACIÓN ANUAL", res: "OK" },
                { elemento: "REPORTE DE EMISIONES", res: "OK" },
                { elemento: "TIPO DE COMBUSTIBLE", res: "OK" },
                { elemento: "CONSUMO MENSUAL", res: "OK" },
                { elemento: "EQUIPOS DE CONTROL", res: "OK" },
                { elemento: "CHIMENEAS", res: "OK" },
                { elemento: "PUERTOS DE MUESTREO", res: "OK" },
                { elemento: "PLATAFORMAS DE MUESTREO", res: "OK" },
                { elemento: "OBSERVACIONES:", res: "Observaciones prueba Observaciones prueba Observaciones prueba Observaciones prueba Observaciones prueba" }
            ];

            var datos_aguas_residuales = [
                { elemento: "REGISTRO DE DESCARGA", res: "OK" },
                { elemento: "PERMISO DE DESCARGA", res: "OK" },
                { elemento: "ANÁLISIS DE DESCARGA", res: "OK" },
                { elemento: "DECLARACIONES TRIMESTRALES", res: "OK" },
                { elemento: "CPD", res: "OK" },
                { elemento: "PTAR", res: "OK" },
                { elemento: "RED DRENAJE", res: "OK" },
                { elemento: "SIST. PRETRATAM.", res: "OK" },
                { elemento: "BITÁCORA DE MANTENIMIENTO", res: "OK" },
                { elemento: "MED. DE FLUJO", res: "OK" },
                { elemento: "OBSERVACIONES:", res: "Observaciones prueba Observaciones prueba Observaciones prueba Observaciones prueba Observaciones prueba" }
            ];

            var datos_residuos_peligrosos = [
                { elemento: "MANIFIESTOS", res: "" },
                { elemento: "REGISTRO COMO GENERADOR", res: "" },
                { elemento: "PLAN DE MANEJO DE RP", res: "" },
                { elemento: "BITÁCORAS DE GENERACIÓN", res: "" },
                { elemento: "BITÁCORAS DE ENTRADA Y SALIDA", res: "" },
                { elemento: "RESPONSABLE TÉCNICO", res: "" },
                { elemento: "BITÁCORA DE TRAZABILIDAD (DESTINO FINAL Y/O TRATAMIENTO)", res: "" },
                { elemento: "REPORTE DE DERRAME", res: "" },
                { elemento: "PAGO DE DERECHOS", res: "" }
            ];

            var datos_impacto_riesgo_ambiental = [
                { elemento: "HOJAS DE SEGURIDAD", res: "" },
                { elemento: "LISTADO DE MATERIALES PELIGROSOS", res: "" },
                { elemento: "TANQUES DE ALMACENAMIENTO", res: "" },
                { elemento: "BITÁCORAS DE GENERACIÓN", res: "" },
                { elemento: "EQ. SUJETO A PRESION", res: "" },
                { elemento: "RESOLUTIVOS", res: "" },
                { elemento: "OBSERVACIONES:", res: "" }
            ];

            var datos_agua_suministro = [
                { elemento: "TÍTULO CONCESIÓN CONAGUA", res: "" },
                { elemento: "DECLARACIONES MENSUALES/TRIMESTRALES", res: "" },
                { elemento: "CONTRATO SAyDM", res: "" },
                { elemento: "RECIBOS ÚLTIMOS TRES MESES", res: "" },
                { elemento: "OBSERVACIONES:", res: "" }
            ];

            var nestedTableCell = {
                content: '',
                styles: { minCellHeight: 100 },
            }

            doc.autoTable({
                theme: 'plain',
                head: [['MOTIVO DE VISITA', 'EMISIONES A LA ATMÓSFERA', 'AGUAS RESIDUALES']],
                body: [[nestedTableCell]],
                startY: 55,
                headStyles: { halign: 'center', cellWidth:'wrap' },
                bodyStyles: { cellWidth:'wrap' },
                styles: { cellPadding: 0.5, fontSize: 8},
                pageBreak: 'avoid',
                didDrawCell: function (data) {
                if (data.row.index === 0 && data.row.section === 'body') {
                    if(data.column.index === 0){
                        doc.autoTable({
                        startY: data.cell.y + 2,
                        margin: { left: data.cell.x + 2 },
                        tableWidth: data.cell.width - 4,
                        theme: 'plain',
                        styles: {
                            maxCellHeight: 4,
                            cellPadding: 0.5, 
                            fontSize: 8
                        },
                        columns: columns_tabla,
                        body: datos_motivo_visita,
                        headStyles: {
                            textColor: [255, 255, 255], // Color de texto blanco (transparente)
                            fontStyle: 'normal',        // Estilo de fuente normal
                        },
                        bodyStyles: {
                            lineWidth: 0.2,
                            lineColor: [0, 0, 0],
                        },
                        });
                    } else if (data.column.index === 1){
                        doc.autoTable({
                        startY: data.cell.y + 2,
                        margin: { left: data.cell.x + 2 },
                        tableWidth: data.cell.width - 4,
                        theme: 'plain',
                        styles: {
                            maxCellHeight: 4,
                            cellPadding: 0.5, 
                            fontSize: 8
                        },
                        columns: columns_tabla,
                        body: datos_emisiones_atmos,
                        headStyles: {
                            textColor: [255, 255, 255], // Color de texto blanco (transparente)
                            fontStyle: 'normal',        // Estilo de fuente normal
                        },
                        bodyStyles: {
                            lineWidth: 0.2,
                            lineColor: [0, 0, 0],
                        },
                        });
                    } else {
                        doc.autoTable({
                        startY: data.cell.y + 2,
                        margin: { left: data.cell.x + 2 },
                        tableWidth: data.cell.width - 4,
                        theme: 'plain',
                        styles: {
                            maxCellHeight: 4,
                            cellPadding: 0.5, 
                            fontSize: 8
                        },
                        columns: columns_tabla,
                        body: datos_aguas_residuales,
                        headStyles: {
                            textColor: [255, 255, 255], // Color de texto blanco (transparente)
                            fontStyle: 'normal',        // Estilo de fuente normal
                        },
                        bodyStyles: {
                            lineWidth: 0.2,
                            lineColor: [0, 0, 0],
                        },
                        });
                    }
                    
                }
                },
            });

            doc.autoTable({
                theme: 'plain',
                head: [['RESIDUOS PELIGROSOSOS', 'IMPACTO Y RIESGO AMBIENTAL', 'AGUA DE SUMINISTRO']],
                body: [[nestedTableCell]],
                startY: doc.lastAutoTable.finalY - 40,
                headStyles: { halign: 'center', cellWidth:'wrap' },
                styles: { cellPadding: 0.5, fontSize: 8},
                pageBreak: 'avoid',
                didDrawCell: function (data) {
                if (data.row.index === 0 && data.row.section === 'body') {
                    if(data.column.index === 0){
                        doc.autoTable({
                        startY: data.cell.y + 2,
                        margin: { left: data.cell.x + 2 },
                        tableWidth: data.cell.width - 4,
                        theme: 'plain',
                        styles: {
                            maxCellHeight: 4,
                            cellPadding: 0.5, 
                            fontSize: 8
                        },
                        columns: columns_tabla,
                        body: datos_residuos_peligrosos,
                        headStyles: {
                            textColor: [255, 255, 255], // Color de texto blanco (transparente)
                            fontStyle: 'normal',        // Estilo de fuente normal
                        },
                        bodyStyles: {
                            lineWidth: 0.2,
                            lineColor: [0, 0, 0],
                        },
                        });
                    } else if (data.column.index === 1){
                        doc.autoTable({
                        startY: data.cell.y + 2,
                        margin: { left: data.cell.x + 2 },
                        tableWidth: data.cell.width - 4,
                        theme: 'plain',
                        styles: {
                            maxCellHeight: 4,
                            cellPadding: 0.5, 
                            fontSize: 8
                        },
                        columns: columns_tabla,
                        body: datos_impacto_riesgo_ambiental,
                        headStyles: {
                            textColor: [255, 255, 255], // Color de texto blanco (transparente)
                            fontStyle: 'normal',        // Estilo de fuente normal
                        },
                        bodyStyles: {
                            lineWidth: 0.2,
                            lineColor: [0, 0, 0],
                        },
                        });
                    } else {
                        doc.autoTable({
                        startY: data.cell.y + 2,
                        margin: { left: data.cell.x + 2 },
                        tableWidth: data.cell.width - 4,
                        theme: 'plain',
                        styles: {
                            maxCellHeight: 4,
                            cellPadding: 0.5, 
                            fontSize: 8
                        },
                        columns: columns_tabla,
                        body: datos_agua_suministro,
                        headStyles: {
                            textColor: [255, 255, 255], // Color de texto blanco (transparente)
                            fontStyle: 'normal',        // Estilo de fuente normal
                        },
                        bodyStyles: {
                            lineWidth: 0.2,
                            lineColor: [0, 0, 0],
                        },
                        });
                    }
                    
                }
                },
            });
            /*
            doc.autoTable(columns_tabla, datos_motivo_visita, {
                startY: 90,
                //finalY: 90 + 35,
                //showHead: 'Tabla 1',
                theme: 'plain',
                rowPageBreak: 'avoid',
                pageBreak: 'auto',
                halign: 'center',
                tableWidth: 'wrap',
                //tableLineWidth: 0.5,
                //tableLineColor: [0, 0, 0],
                styles: { cellPadding: 0.5, fontSize: 8 },
                headStyles: {
                    textColor: [255, 255, 255], // Color de texto blanco (transparente)
                    fontStyle: 'normal',        // Estilo de fuente normal
                },
                bodyStyles: {
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                },
            });*/

            
            //doc.text("Ciudad y Fecha: " + fecha.getDay() + " de " + mes + " del " + fecha.getFullYear() , 140, 42,{align: "left"});

            /*
            doc.text("Ciudad y Fecha: " + this.dataPDF_lugarFecha, 110, 40, { align: "left" });
            doc.text("Fecha Emisión: " + this.dataPDF_fechaEmision, 15, 40, { align: "left" });

            doc.setFontSize(15);
            
            doc.setFontSize(10);
            doc.text("Numero Cotización: " + this.dataPDF_codigocotizacion, 15, 65, { align: "left" });
            doc.text("Nombre: " + this.dataPDF_nombre, 15, 75, { align: "left" });
            doc.text("RFC: " + this.dataPDF_RFC, 100, 65, { align: "left" });


            let splitTitle = doc.splitTextToSize(this.dataPDF_razonSocial, 75);
            doc.text(123, 75, splitTitle);
            doc.text("Razon Social: ", 100, 75, { align: "left" });


            doc.text("Fecha Vigencia: " + this.dataPDF_fechaVigencia, 15, 85, { align: "left" });


            var columns_servicios = [
                { title: "Servicio", dataKey: "servicio" },
                { title: "Cantidad", dataKey: "cantidad" },
                { title: "Subtotal", dataKey: "subtotal" },
                { title: "IVA", dataKey: "iva" },
                { title: "Total", dataKey: "total" },
            ];

            doc.autoTable(columns_servicios, serviciosJSON, {
                startY: 90,
                finalY: 90 + 35,
                theme: 'grid',
                rowPageBreak: 'avoid',
                pageBreak: 'auto',
                halign: 'center',
            });

            var columns_terminos = [
                { title: "Términos y Condiciones", dataKey: "termino" },
            ];
            doc.autoTable(columns_terminos, terminosJSON, {
                startY: 130,
                finalY: 130 + 35,
                theme: 'grid',
                rowPageBreak: 'avoid',
                pageBreak: 'auto',
                headStyles: { halign: 'center' },
            });

            doc.setFontSize(15);
            //doc.text("Observaciones:" + this.dataPDF_observaciones, 20, 250, { align: "left" });

            var img = new Image();
            img.src = require("../assets/logo.png");
            doc.addImage(img, "PNG", 15, 15, 50, 20);
            */
            doc.autoPrint();
            window.open(
                URL.createObjectURL(doc.output("blob")),
                "_blank",
                "height=650,width=500,scrollbars=yes,location=yes"
            );
            setTimeout(() => {
                window.URL.revokeObjectURL(doc.output("bloburl"));
            }, 100);


        },
        
    },
};
